import axios from 'axios';
import { useEffect, useState } from "react";
import headerMainLeftLine from "../assets/headerMainLeftLine.png";
import ofer from "../assets/Offer.pdf";
import Person from "../assets/Person.pdf"
import { Link, useNavigate } from "react-router-dom";
import { MAIN_URL } from "../routes/consts";

function Registration() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    console.log('You clicked submit.');
  }

  const postForm = async (e) => {
    e.preventDefault();
    try {
      const response = await axios({
        method: 'post',
        url: `${MAIN_URL}register`,
        withCredentials: false,
        data: {
          firstname: name,
          lastname: lastName,
          password: password,
          phone: phone,
          email: email,
          weight: weight,
          height: height
        },
      });
      navigate('/login');
      console.log(response.data);
    } catch (error) {
      console.error('Ошибка:', error);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="auth__title">Зарегистрироваться</h1>
      <input type="text" required className="auth__input" value={name} onChange={(e) => setName(e.target.value)} placeholder='Имя' />
      <input type="text" required className="auth__input" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Фамилия' />
      <input type="email" required className="auth__input" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
      <input type="number" required className="auth__input" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Номер телефона' />
      <input type="password" required className="auth__input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Пароль' />
      <input type="weight" required className="auth__input" value={weight} onChange={(e) => setWeight(e.target.value)} placeholder='Вес' />
      <input type="height" required className="auth__input" value={height} onChange={(e) => setHeight(e.target.value)} placeholder='Рост' />
      <div className="auth__checkbox-group">
          <input className="auth__checkbox" required type="checkbox" name="agreement" value="politics" />
          <label className="auth__checkbox-label" for="politics">я принимаю условия <a className="auth__link link" href={Person}>политики обработки персональных данных</a></label>
      </div>
      <div className="auth__checkbox-group">
          <input className="auth__checkbox" required type="checkbox" name="agreement" value="politics" />
          <label className="auth__checkbox-label" for="politics">я принимаю условия <a className="auth__link link" href={ofer}>оферты</a></label>
      </div>
      <div className="auth__bottom-section">
          <img className="auth__line" alt='черточка для красивого дизайна' src={headerMainLeftLine} />
          <button className="button auth__submit-button button" type="submit" onClick={postForm}>зарегистрироваться</button>
      </div>
    </form>
  );
}

export default Registration;
