import React from "react";
import {Routes, Route, Navigate} from 'react-router-dom';
import MainPage from "../pages/mainPage";
import { authRoutes } from "./routes";
import { publicRoutes } from "./routes";

const AppRouter = () => {



    return (
        <Routes> 
            {authRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={<Component />} exact />
            )}
            {publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={<Component />}  exact ></Route>
            )}
            <Route path='*' element={<Navigate to={MainPage} />} />
        </Routes>
    );
};

export default AppRouter;