import { useState } from "react";
import cl from './programModal.module.css';
import axios from 'axios';
import { MAIN_URL, LOGIN_ROUTE } from "../../routes/consts";
import { useNavigate } from 'react-router-dom';
import programModalLine from '../../assets/programModalLine.png';

const ProgramModal = ({method, visible, card}) => {
    const navigate = useNavigate();
    const [showNotification, setShowNotification] = useState(false);
    const rootClasses = [cl.myModal];
    console.log(visible);
    if (visible) {
        rootClasses.push(cl.active);
    }

    const handleBuyClick = async () => {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${MAIN_URL}buy_course/${card.id}`, {
            headers: {
                'access-token': `${token}`
            }
        }).then((response) => {
            const redirectURL = response.data.redirect_url;
            window.location.href = redirectURL;
        })
    console.log("response")
            console.error('Ошибка при покупке курса:', response);
        } catch (error) {
            if (error.response && error.response.status ===  401) {
                navigate(LOGIN_ROUTE);
            }
            console.error('Ошибка:', error);
        }
      };

    return (
        <div className={rootClasses.join(' ')}>
                {card && (
            <div className={cl.myModalContent} >
                <div className="wrap--program-modal program-modal">
                    <div className="program-modal__content">
                        <div className="program-modal__section">
                            <button className='button-exit' onClick={(e) => method(false)}>
                                <div className='button-exit__left'></div>
                                <div className='button-exit__right'></div>
                            </button>
                            <h1 className="program-modal__title">{card.title}</h1>
                            <h2 className="program-modal__subtitle">{card.subtitle}</h2>
                            <ol className="list">
                                {card.listItems.map((item, index) => (
                                    <li className="program-modal__list-item list__item" key={index}>
                                        <span className="program-modal__list-num list__num">{index + 1}</span>
                                        <div>{item}</div>
                                    </li>
                                ))}
                            </ol>
                        </div>
                        <div className="program-modal__section">
                            <p className="program-modal__description">{card.description}</p>
                            <p className="program-modal__title-two">Курс включает:</p>
                            <ol className="program-modal__list list">
                                {card.courseIncludes.map((item, index) => (
                                    <li className="program-modal__list-item list__item about-description-two__text" key={index}>
                                        <span className="program-modal__list-num list__num">{index + 1}</span>
                                        <div>{item}</div>
                                    </li>
                                ))}
                            </ol>
                            <p>{card.priceTitle}</p>
                            <button className="program-modal__button" onClick={handleBuyClick}>Купить</button>
                        </div>
                    </div>
                    <div className="program-modal__images">
                        <img src={card.image} alt="фото девушки выполняющая упражнение" className="program-modal__img" />
                        <img src={programModalLine} alt="линии в форме лепестка" className="program-modal__image program-modal__image--line" />
                    </div>
                </div>
            </div>
                )}
        </div>
    );
    
}

export default ProgramModal;