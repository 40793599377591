import { MAIN_ROUTE, PROFILE_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE } from "./consts"
import MainPage from "../pages/mainPage"
import AuhtPage from "../pages/authPage"
import ProfilePage from "../pages/profilePage"

export const authRoutes = [
    {
        path: PROFILE_ROUTE,
        Component: ProfilePage
    }
]

export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        Component: MainPage
    },
    {
        path: LOGIN_ROUTE,
        Component: AuhtPage
    },
    {
        path: REGISTRATION_ROUTE,
        Component: AuhtPage
    }
]