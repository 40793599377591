import educationStandGirl from '../assets/educationStandGirl.webp';
import educationLiesGirl from '../assets/educationLiesGirl.webp';
import educationSertificate from '../assets/educationSertificate.webp';
import educationArrowLeft from '../assets/educationArrowLeft.png';
import educationArrowRight from '../assets/educationArrowRight.png';

import { Carousel } from '@trendyol-js/react-carousel';
import { useEffect, useLayoutEffect, useState } from 'react';

const Education = () => {

    const [values, setValues] = useState(1);

    useLayoutEffect(() => {
        console.log(window.innerWidth);
        if (window.innerWidth <= 1500) {
            setValues(1);
        } else {
            setValues(2);
        }
    }, [])

    console.log(values);
    return (
        <div className='ed__main'>
        <div id="education" className="wrap wrap--education education">
            <h1 className="education__title">Образование</h1>
            <div className="education__content">
                <div className="education__content-heading">
                    <p className="education__text">
                        Я нахожусь в постоянном развитии и получаю новые знания беспрерывно, для того, чтобы привести клиента к результату грамотно и разумно
                    </p>
                    <img className="education__image education__image--stand-girl" src={educationStandGirl} alt='фото девушки выполняющая упражнения' />
                </div>
                <div className="education__subcontent">
                    <p className="education__text education__text--big">
                        Я училась у лучших специалистов-эндокринологии, диетологии:<br />
                    </p>
                    <span className="education__text">
                        Данные знания дают мне возможность правильно составить меню и изучить ваше гормональное состояние
                    </span>
                    <img className="education__image education__image--lies" src={educationLiesGirl} alt='фото девушки выполняющая упражнения' />
                </div>
            </div>
            {/* <div className="education__images">
                <img className="education__image education__image--stand-girl" src={educationStandGirl} alt='фото девушки выполняющая упражнения' />
                <img className="education__image education__image--lies" src={educationLiesGirl} alt='фото девушки выполняющая упражнения' />
            </div> */}

            <div className="education__certificates">
                <h1 className="education__certificates-title">Сертификаты</h1>
                <Carousel className="education__carousel" show={values} slide={1} swiping={true} leftArrow={<img className="education__carousel-arrow" src={educationArrowLeft} />} rightArrow={<img className="education__carousel-arrow education__carousel-arrow--right" src={educationArrowRight} />}>
                    <div className="education__certificate">
                        <img src={educationSertificate} alt="сертификат диетология и биохимия в фитнесе" className="education__certificate-image" />
                        <p className="education__certificate-text">«Диетология и биохимия в фитнесе»</p>
                    </div>
                    <div className="education__certificate">
                        <img src={educationSertificate} alt="сертификат пищевая зависимость" className="education__certificate-image" />
                        <p className="education__certificate-text">«Пищевая зависимость»</p>
                    </div>
                    <div className="education__certificate">
                        <img src={educationSertificate} alt="сертификат пищевая зависимость" className="education__certificate-image" />
                        <p className="education__certificate-text">«Пищевая зависимость2»</p>
                    </div>
                    <div className="education__certificate">
                        <img src={educationSertificate} alt="сертификат пищевая зависимость" className="education__certificate-image" />
                        <p className="education__certificate-text">«Пищевая зависимость2»</p>
                    </div>
                </Carousel>
            </div>
        </div>
        </div>
    );
}

export default Education;
