import axios from 'axios';
import { useState, useEffect } from 'react';
import profilePageLine from '../assets/profilePageLine.svg';
import { MAIN_URL } from "../routes/consts";

const ProfilePage = () => {

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [course, setCourse] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${MAIN_URL}get_user`, {
                    headers: {
                        'access-token': `${token}`
                    }
                });
                const data = response.data;
                setName(data.firstname);
                setLastName(data.lastname);
                setEmail(data.email);
                setPhone(data.phone);
                setHeight(data.height);
                setWeight(data.weight);
                setCourse(data.courses);
            } catch (error) {
                console.error('Ошибка:', error);
            }
        };
        fetchProfile();
    }, []);

    return (
        <div className='wrap wrap--profilePage profilePage'>
            <div className='profilePage__header'>
                <h1 className='profilePage__title'>Личный кабинет</h1>
                <div className='profilePage__container-image'>
                    <img className='profilePage__image' alt="лепесток" src={profilePageLine} />
                </div>
            </div>
            <h2 className='profilePage__subtitle'>Мой профиль</h2>
            <div className='profilePage__content'>
                <div className='profilePage__form'>
                    <div className='profilePage__input-group'>
                        <span className='profilePage__input-label'>Имя</span>
                        <input className='profilePage__input' placeholder='Имя' disabled={true} value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='profilePage__input-group'>
                        <span className='profilePage__input-label'>Фамилия</span>
                        <input className='profilePage__input' placeholder='Фамилия' disabled={true} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <div className='profilePage__input-group'>
                        <span className='profilePage__input-label'>Email</span>
                        <input className='profilePage__input' placeholder='Email' disabled={true} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='profilePage__input-group'>
                        <span className='profilePage__input-label'>Телефон</span>
                        <input className='profilePage__input' placeholder='Телефон' disabled={true} value={phone}  />
                    </div>
                    <div className='profilePage__input-group'>
                        <span className='profilePage__input-label'>Рост</span>
                        <input className='profilePage__input' placeholder='Рост' disabled={true} value={height} onChange={(e) => setHeight(e.target.value)} />
                    </div>
                    <div className='profilePage__input-group'>
                        <span className='profilePage__input-label'>Вес</span>
                        <input className='profilePage__input' placeholder='Вес' disabled={true} value={weight} onChange={(e) => setWeight(e.target.value)} />
                    </div>
                    <div className='profilePage__input-group'>
                        <span className='profilePage__input-label'>Курсы</span>
                        <input className='profilePage__input input2' placeholder='Курсы' disabled={true} value={course} onChange={(e) => setCourse(e.target.value)} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;
