import React, { useEffect, useState } from 'react';
import image1 from '../assets/image_1.webp';
import image2 from '../assets/image_2.webp';
import image3 from '../assets/image_3.webp';
import image4 from '../assets/image_4.webp';
import ProgramModal from './modals/programModal';

const Slider = () => {

  const [modal, setModal] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);

  const openModal = (card) => {
    setCurrentCard(card);
    setModal(true);
  }

  const closeModal = () => {
    setCurrentCard(null);
    setModal(false);
  }

  const cards = [
    {
      id: 1,
      title: "Базовый",
      subtitle: "Курс `Базовый` предназначен для тех, кто только начинает свой путь в мире фитнеса или редко занимается спортом. Этот курс идеально подходит для тех, кто хочет улучшить свою физическую форму, но не имеет большого опыта в тренировках. В рамках данного курса предусмотрено 3 тренировки в неделю",
      price: '4299 ₽',
      listItems: [
        "похудение, поддержание или набор веса (зависит от питания)",
        "построение идеальной формы ягодиц и ног, ручек и спины"
      ],
      description: "Для данного курса вам понадобится коврик, утяжелитель для ног, длинный эспандер легкие гантели.",
      courseIncludes: [
        "12 авторских силовых тренировок на 30 дней",
        "план питания на 30 дней",
        "базу знаний о питании и тренировках"
      ],
      priceTitle: "Стоимость:  4299 ₽",
      image: image1,
    },
    {
      id: 2,
      title: "Идеальные ягодицы",
      subtitle: "Курс `Идеальные ягодицы` разработан для тех, кто хочет сделать свои ягодицы привлекательными и подтянутыми. Этот базовый курс идеально подходит для тех, кто только начинает свой путь в фитнесе или занимается спортом редко. В рамках этого курса предусмотрено 3 тренировки в неделю.",
      price: '3900 ₽',
      listItems: [
        "построение идеальной формы ягодиц",
        "сделает ваши ножки аккуратными"
      ],
      description: "Для данного курса вам понадобится коврик,  утяжелитель для ног, длинный эспандер",
      courseIncludes: [
        "12 авторских силовых тренировок на 30 дней",
        "план питания на 30 дней",
        "базу знаний о питании и тренировках"
      ],
      priceTitle: "Стоимость:  3900 ₽",
      image: image2,
    },
    {
      id: 3,
      title: "Фитнес-резинки верх тела",
      subtitle: "Этот курс предназначен для тех, кто хочет укрепить верхнюю часть тела и создать красивую и подтянутую мускулатуру. `Фитнес-резинки верх тела` - это отличный выбор для начинающих и тех, кто редко занимается фитнесом. Вам предстоит выполнять 3 тренировки в неделю.",
      price: '3900 ₽',
      listItems: [
        "улучшение физической формы верхней части тела",
        "сможете работать над укреплением плеч, спины, груди и рук"
      ],
      description: "Для успешного прохождения этого курса вам потребуются фитнес-резинки различной степени сопротивления, а также коврик для выполнения упражнений.",
      courseIncludes: [
        "12 авторских силовых тренировок на 30 дней",
        "план питания на 30 дней",
        "базу знаний о питании и тренировках"
      ],
      priceTitle: "Стоимость:  3900 ₽",
      image: image3,
    },
    {
      id: 4,
      title: "Фитнес-резинки низ тела",
      subtitle: "`Фитнес-резинки низ тела` - это курс, который сосредотачивается на укреплении и формировании нижней части вашего тела. Этот базовый курс идеально подходит для тех, кто только начал заниматься фитнесом или редко уделяет этому время. В рамках этого курса предусмотрено 3 тренировки в неделю.",
      price: '3900 ₽',
      listItems: [
        "улучшение физической формы нижней части тела.",
        "Упор делается на бедра, ягодицы и ноги"
      ],
      description: "Для успешного прохождения этого курса вам потребуются фитнес-резинки различной степени сопротивления, а также коврик для выполнения упражнений",
      courseIncludes: [
        "12 авторских силовых тренировок на 30 дней",
        "план питания на 30 дней",
        "базу знаний о питании и тренировках"
      ],
      priceTitle: "Стоимость:  3900 ₽",
      image: image4,
    },
  ];
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.3.5/css/swiper.min.css';
        document.head.appendChild(link);
    
        const script1 = document.createElement('script');
        script1.src = 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.3.5/js/swiper.min.js';
        script1.async = true;
        document.body.appendChild(script1);
    
        script1.onload = () => {
          const script2 = document.createElement('script');
          script2.textContent = `
            var swiper = new Swiper('.blog-slider', {
              spaceBetween: 30,
              effect: 'fade',
              loop: true,
              mousewheel: {
                invert: false,
              },
              pagination: {
                el: '.blog-slider__pagination',
                clickable: true,
              }
            });
          `;
          document.body.appendChild(script2);
        };
    
        return () => {
          document.head.removeChild(link);
          document.body.removeChild(script1);
        };
      }, []);


  return (
    <>
    <ProgramModal visible={modal} method={closeModal} card={currentCard} />
    <div className='blog'>
    <a name="program_slider"></a>
      <div className="blog-slider">
        <div className="blog-slider__wrp swiper-wrapper">
          {cards.map((card, index) => (
            <div className="blog-slider__item swiper-slide" key={index}>
              <div className="blog-slider__img">
                <img src={card.image} alt="" />
              </div>
              <div className="blog-slider__content">
               <div className='blog-flex'>
               <div className="blog-slider__title">{card.title}</div>
               <div className="blog-slider__price">{card.price}</div>
               </div>
                <div className="blog-slider__text">{card.subtitle}</div>
                <a onClick={() => openModal(card)} href="#programitem" className="blog-slider__button">Подробнее</a>
              </div>
            </div>
          ))}
        </div>
        <div className="blog-slider__pagination link"></div>
      </div>
    </div>
  </>
);
};

export default Slider;
