import React from "react";
import { useLocation } from "react-router-dom";
import { LOGIN_ROUTE } from "../routes/consts";
import Login from "../components/login";
import Registration from "../components/registration";

const AuthPage = () => {


    const location = useLocation();
    const isLogin = location.pathname === LOGIN_ROUTE;



    const submitForm = async (e) => {
        e.preventDefault();

    }

    return (
        <form onSubmit={submitForm} className="wrap auth">
            <div className="wrap-auth">
                {
                    isLogin ? 
                       <Login />
                        :
                        <Registration />
                }
            </div>
        </form>
    );
};

export default AuthPage;