import { useState,useEffect } from "react";
import ProfileMenu from "./modals/profileMenu";
import siteLogo from '../assets/siteLogo.webp';
import profileLogo from '../assets/profileImage.png';
import { useLocation, useNavigate } from "react-router-dom";
import { LOGIN_ROUTE, MAIN_ROUTE, PROFILE_ROUTE, REGISTRATION_ROUTE } from "../routes/consts";

const Header = () => {

    const [modal, setModal] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const isMainPage = location.pathname === MAIN_ROUTE;
    const [isAuth, setIsAuth] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuth(true);
        }
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuth(false);
        navigate(MAIN_ROUTE);
    }

    const openModal = () => {
        if (!modal) {
            setModal(true);
        } else {
            setModal(false);
        }
    }

    const openMobileModal = () => {
        openModal();
    }

    const navigateUser = (e, name) => {
        e.preventDefault();

        switch (name) {
            case 'Войти':
                navigate(LOGIN_ROUTE);
                openModal();
                break;
            case 'Зарегестрироваться':
                navigate(REGISTRATION_ROUTE);
                openModal();
                break;
            case 'Профиль':
                console.log('Отработал PROFILE')
                navigate(PROFILE_ROUTE);
                openModal();
            default:
                navigate(MAIN_ROUTE)
                openModal();
        }

        
    }

    return (
        <div className="wrap header wrap--header wrap-header--main">
            <a onClick={() => navigate(MAIN_ROUTE)} className="header__link"><img src={siteLogo} alt="логотип сайта" className="header__logo"/></a>
            <nav className="navigation header__navigation">
                <ul className="navigation__list">
                    {isMainPage ? 
                        <>
                            <a onClick={() => navigate(MAIN_ROUTE)} className="navigation__link"><li className="navigation__item">Главная</li></a>
                            <a href="#program_slider" className="navigation__link"><li className="navigation__item">Программы</li></a>
                            <a href="#about" className="navigation__link"><li className="navigation__item">Обо мне</li></a>
                            <a href="#education" className="navigation__link"><li className="navigation__item">Образование</li></a>
                            <a href="#contacts" className="navigation__link"><li className="navigation__item">Контакты</li></a>
                        </>
                        :
                        <>
                            <a onClick={() => navigate(MAIN_ROUTE)} className="navigation__link"><li className="navigation__item">Главная</li></a>
                            <a onClick={() => navigate(MAIN_ROUTE)} className="navigation__link"><li className="navigation__item">Программы</li></a>
                            <a onClick={() => navigate(MAIN_ROUTE)} className="navigation__link"><li className="navigation__item">Обо мне</li></a>
                            <a onClick={() => navigate(MAIN_ROUTE)} className="navigation__link"><li className="navigation__item">Образование</li></a>
                            <a onClick={() => navigate(MAIN_ROUTE)} className="navigation__link"><li className="navigation__item">Контакты</li></a>
                            <ol className="list header-list">
                    </ol>
                        </>
                    }
                </ul>    
            </nav>
            <div className="profile profile-container">
                <button className="profile__button" onClick={openModal}>
                    <img className="profile__logo" src={profileLogo} alt="логотип профиля"/>
                    <div className="profile__logo--mobile-container">
                        <hr className="profile__logo--mobile" />
                        <hr className="profile__logo--mobile" />
                        <hr className="profile__logo--mobile" />
                    </div>
                </button>
                <ProfileMenu visible={modal} setVisible={setModal}>
                    <ol className="list header-list">
                        {
                            isAuth ?
                                <>
                                    <li onClick={() => {navigate(PROFILE_ROUTE); openModal()}} className="list__item header-list__item">Мой профиль</li>
                                    <li onClick={logout} className="list__item header-list__item">Выйти</li>
                                </>
                                :
                                <>
                                    <li onClick={(e) => navigateUser(e, 'Войти')} className="list__item header-list__item">Войти</li>
                                    <li onClick={(e) => navigateUser(e, 'Зарегестрироваться')} className="list__item header-list__item">Зарегестрироваться</li>
                                </>
                        }
                    </ol>

                    <ol className="list header-list header-list--mobile wrap">
                        <button className='button-exit button-exit--menu' onClick={openMobileModal}>
                                <div className='button-exit__left'></div>
                                <div className='button-exit__right'></div>
                        </button>
                        {
                            isAuth ? 
                            <>
                                <a onClick={(e) => navigateUser(e)} className="link"><li className="list__item header-list__item">Главная</li></a>
                                <a onClick={openMobileModal}className="link" href="#programs"><li className="list__item header-list__item">Программы</li></a>
                                <a onClick={openMobileModal}className="link" href="#about"><li className="list__item header-list__item">Обо мне</li></a>
                                <a onClick={openMobileModal}className="link" href="#education"><li className="list__item header-list__item">Образование</li></a>
                                <a onClick={openMobileModal}className="link" href="#contacts"><li className="list__item header-list__item">Контакты</li></a>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a onClick={() => {navigate(PROFILE_ROUTE); openMobileModal()}} className="link"><li className="list__item header-list__item">Мой профиль</li></a>
                                {/* <a onClick={(e) => navigateUser(e, 'Профиль')} className="link"><li className="list__item header-list__item">Выйти</li></a> */}
                                <li onClick={logout} className="list__item header-list__item">Выйти</li>
                            </>
                            :
                            <>
                                <a onClick={(e) => navigateUser(e)} className="link"><li className="list__item header-list__item">Главная</li></a>
                                <a onClick={(e) => navigateUser(e)} className="link"><li className="list__item header-list__item">Программы</li></a>
                                <a onClick={(e) => navigateUser(e)} className="link"><li className="list__item header-list__item">Обо мне</li></a>
                                <a onClick={(e) => navigateUser(e)} className="link"><li className="list__item header-list__item">Образование</li></a>
                                <a onClick={(e) => navigateUser(e)} className="link"><li className="list__item header-list__item">Контакты</li></a>
                                <a onClick={(e) => navigateUser(e, 'Войти')} className="link"><li className="list__item header-list__item">Войти/Зарегестрироваться</li></a>
                            </>
                        }
                    </ol>
                </ProfileMenu>
            </div>
        </div>
    );
}

export default Header;