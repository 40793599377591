import programsIntroGirl from '../assets/programsIntroGirl.webp';
import programsIntroGirlSeed from '../assets/programsIntroGirlSeed.png';
import heart from '../assets/heart.png';
import programsCreatorGirl from '../assets/programsCreatorGirl.webp';


const Programs = () => {
    return (
        <div className='prog__main'>
        <div id='programs' className="wrap wrap--programs programs">
            <div className="programs__intro">
                <h2 className="programs__title">
                    <span>Я разработала для вас уникальные программы,</span> <br></br>
                    основанные на простых, но очень эффективных и <br></br>
                    продуктивных упражнений <br></br>
                    <br></br>
                    Данные программы позволят вам сформировать <br></br>
                    крепкое здоровье, <span>красивую фигуру,</span> реабилитировать <br></br>
                    травмы и улучшить самочувствие! <br></br>
                    <br></br>
                    <span>Каждая программа гибкая и универсальная,</span> что <br></br>
                    позволит каждой девушки, с разными типами <br></br>
                    телосложения выбрать курс по своему запросу<br></br>
                </h2>
                <div className="programs__images">
                    <img className="programs__image programs__image--girl-stand" src={programsIntroGirl} alt='фото девушки упражнение с резинкой' />
                    <img className="programs__image programs__image--girl-seed" src={programsIntroGirlSeed} alt='фото девушки упражнение приседания' />
                </div>
            </div>
            <div className="programs__approach">
                <p className="programs__text">
                    Я использую комплексный подход <br></br>
                    при создании вашего тела: <br></br>
                    <br></br>
                    <span className="programs__highlight">
                        Анатомия, биомеханика, <br></br>
                        физиология - основа <br></br>
                        основ в фитнесе <br></br>
                    </span>
                </p>
                <div className="programs__creator">
                    <div className="programs__creator-container">
                        <img className="programs__image programs__image--heart" alt="сердечко" src={heart} />
                        <h1 className="programs__creator-title">
                            Моя работа- <br></br>
                            творение женских тел!
                        </h1>
                    </div>
                    <img className="programs__creator-image programs__creator-im" src={programsCreatorGirl} alt='фото девушки сидящей на шпагате' />
                </div>
            </div>
            {/* <div className="programs__section">
                <h1 id="programitem" className="programs__section-title">программы</h1>
                <div className="programs__program">
                    {arrayPrograms.map((item) => 
                        <ProgramItem key={item.name} item={item} />
                    )}
                </div>
                <a className="programs__link" href="#">
                    <span className='programs__link-text'>
                        Все программы
                    </span>
                </a>
            </div> */}
        </div>
        </div>
    );
}

export default Programs;