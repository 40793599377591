import socialInstagram from '../assets/socialInstagram.png';
import socialEmail from '../assets/socialEmail.png';
import socialTelegram from '../assets/socialTelegram.png';
import socialPhone from '../assets/socialPhone.png';
import footerMainGirl from '../assets/footerMainGirl.webp';
import footerMainLine from '../assets/footerMainLine.png';

const FooterMain = () => {
    return (
        <div id="contacts" className="wrap wrap--footerMain footerMain">
            <div className="footerMain__contacts">
                <h1 className="footerMain__title">Контакты</h1>
                <ul className="list footerMain__list">
                    <li className="footerMain__item">
                        <a className="footerMain__link" href="https://www.instagram.com/Valeriya_crossfit/">
                            <img className="footerMain__icon" src={socialInstagram} alt="иконка инстаграмма" />
                            <span className="footerMain__text">Valeriya_crossfit</span>
                        </a>
                    </li>
                    <li className="footerMain__item">
                        <a className="footerMain__link" href="mailto:Leer.1997@mail.ru">
                            <img className="footerMain__icon" src={socialEmail} alt="иконка почты" />
                            <span className="footerMain__text">Leer.1997@mail.ru</span>
                        </a>
                    </li>
                    <li className="footerMain__item">
                        <a className="footerMain__link" href="https://t.me/Valeriya_Pol">
                            <img className="footerMain__icon" src={socialTelegram} alt="иконка телеграма" />
                            <span className="footerMain__text">Valeriya_Pol</span>
                        </a>
                    </li>
                    <li className="footerMain__item">
                        <a className="footerMain__link" href="tel:+79502996876">
                            <img className="footerMain__icon" src={socialPhone} alt="иконка номера телефона" />
                            <span className="footerMain__text">+79502996876</span>
                        </a>
                    </li>
                    {/* //////////////////////////////////////////// */}
                    <li className="footerMain__item">
                        <a className="footerMain__link" href="#">
                            {/* <img className="footerMain__icon" src={socialPhone} alt="иконка номера телефона" /> */}
                            {/* <span className="footerMain__text"></span> */}
                            <span className="footerMain__tex">ПОЛЯКОВА ВАЛЕРИЯ АЛЕКСАНДРОВНА</span>
                        </a>
                    </li>
                    <li className="footerMain__item">
                        <a className="footerMain__link" href="#">
                            {/* <img className="footerMain__icon" src={socialPhone} alt="иконка номера телефона" /> */}
                            <span className="footerMain__tex">ИНН: 253611171820</span>
                        </a>
                    </li>
                    <li className="footerMain__item">
                        <a className="footerMain__link" href="#">
                            {/* <img className="footerMain__icon" src={socialPhone} alt="иконка номера телефона" /> */}
                            <span className="footerMain__tex tex1">ОГРНИП: 323253600022451</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="footerMain__images">
                <img className="footerMain__image footerMain__image--girl" src={footerMainGirl} alt="фото девушки" />
                <img className="footerMain__image footerMain__image--line" src={footerMainLine} alt="рисунок линии" />
            </div>
        </div>
    );
}

export default FooterMain;
