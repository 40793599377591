import axios from 'axios';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import headerMainLeftLine from "../assets/headerMainLeftLine.png";
import { REGISTRATION_ROUTE, MAIN_URL } from "../routes/consts";

function Login() {
  const navigate = useNavigate();
  const [login, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    console.log('You clicked submit.');
  }

  const postLogin = async (e) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${MAIN_URL}login`,
        withCredentials: false,
        data: {
          login,
          password
        },
      });

      const token = response.headers.get('access-token');
      console.log(response.headers.get('access-token'))

      localStorage.setItem('token', token);

      navigate('/');
      window.location.reload()

    } catch (error) {
      setErrorMessage('Неверный  логин или пароль');
    }
  };

  return (
     <form onSubmit={handleSubmit}>
      <h1 className="auth__title">Войти</h1>
      <input type="email" required className="auth__input" value={login} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
      <input type="password" required className="auth__input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Пароль' />
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <div className="auth__checkbox-group">
          <input className="auth__checkbox" required type="checkbox" name="agreement" value="politics" />
          <div></div>
          <label className="auth__checkbox-label" for="politics">Запомни меня</label>
      </div>
      {/* <a className="link link--bad-password">Забыл пароль</a> */}
      <div className="auth__bottom-section">
          <img className="auth__line" alt='черточка для красивого дизайна' src={headerMainLeftLine} />
          <button className="button auth__submit-button button auth__submit-button--login" onClick={postLogin} type="button">Войти</button>
      </div>
      <span className="link link--registration auth__checkbox-text" onClick={() => navigate(REGISTRATION_ROUTE)}>Зарегестрироваться</span>
    </form>
  );
}

export default Login;
