import About from "../components/about";
import Education from "../components/education";
import FooterMain from "../components/footerMain";
import HeaderMain from "../components/headerMain";
import Programs from "../components/programs";
import Slider from "../components/slider";

const MainPage = () => {

    return (
        <>
            <HeaderMain />
            <Programs />
            <Slider />
            <About />
            <Education />
            <FooterMain />
        </>
    );
}

export default MainPage;