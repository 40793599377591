import images from '../assets/image27.webp';
import line from '../assets/highlight.png';
import imageGirl from '../assets/imageGirl.webp';

const About = () => {

    return (
    <div className='about__main'>
        <div id="about" className='wrap about about-container'>
            <div className='about-description'>
                <h2 className='about-description__heading'>Обо мне</h2>
                <div className='about-description-container'>
                    <img className='about-description-container__image' src={images} alt='изоброжение девушки тренера' />
                    <div className='about-description-container__contain'>
                        <p className='about-description-container__text'>
                            <span>Мой фитнес путь начался в 2016 году,</span> когда я професионально 
                            начала заниматься таким видом спорта, как Crossfit. В тот период я 
                            пришла , как «Любитель», но через 4 месяца занятий, <span>я выступила на 
                            первых районных соревнованиях</span>, где заняла 1 место Тогда я и 
                            решила добиться высот в спорте в возрасте 18 лет За 3 года занятий 
                            спортом, я выполнила нормативы:
                            <br/><br/>
                            Мастер спорта России по пауэрлифтингу
                            Кандидат в мастера спорта по Тяжелой атлетике
                            Участница крупных турниров, где успешно занимала от 1 до 3 места и по сей день, я выступаю на сореванованиях и не планирую останавливаться
                        </p>
                        <p className='about-description-container__text about-description-container__text--margin-top'>
                            В настоящее время мой опыт в профессиональном спорте занял 9 лет 
                            <span> Работаю с девушками лично и онлайн 7 лет.</span> В период 
                            дисциплинарных занятий и строго режима, я обучалась, училась и 
                            развивалась Мне было интересно, как мне стать выносливей, 
                            сильней и гибкой и при этом, сохранить свое красивое и 
                            эстетически сексуальное тело <span>Я стала интересоваться фитнесом, 
                            реабилитацией травм, изучала работу спортсменов других видов 
                            спорта.</span> В данное время пришло время нести в массы весь проток 
                            моих знаний в массы
                        </p>
                    </div>
                </div>
            </div>
            <div className='about-description-two'>
                <div className='about-description-two__swap-container'>
                    <div className='about-description-two__container'>
                        <div className='container-one'>
                            <h2 className='about-description-two__text about-description-two__text--heading'>
                                Моя работа – моя жизнь<br></br> 
                                Мое предназначение работа<br></br>  
                                с девушками, делать их увереннее<br></br>  
                                в себе, развивать их женственность<br></br>  
                                и создавать эстетическую красоту<br></br> 
                            </h2>
                            <p className='about-description-two__text'>
                                С моей помощью ваш образ жизни начнет меняться,<br></br> 
                                моя задача не только выстроить соблазнительное<br></br> 
                                тело, но улучшить ваше общее самочувствие<br></br> 
                            </p>
                        </div>
                        <div className='container-two'>
                            <h2 className='about-description-two__text about-description-two__text--heading'>«Меняй тело-меняй жизнь»</h2>
                            <ol className='list'>
                                <li className='list__item about-description-two__text'>
                                    <span className='list__num'>1</span>
                                    <div>
                                        Ежедневная активность улучшить сердечно-<br></br> 
                                        сосудистую, дыхательную системы
                                    </div>
                                </li>
                                <li className='list__item about-description-two__text'>
                                    <span className='list__num'>2</span>
                                    <div>
                                        Работа с тазовым дном улучшает работу<br></br>  
                                        кишечника, повышает либидо
                                    </div>
                                </li>
                                <li className='list__item about-description-two__text'>
                                    <span className='list__num'>3</span>
                                    <div>
                                        Работа со стопой сформирует правильную<br></br>  
                                        ходьбу и ровную осанку
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className='about-description-two__image-container'>
                        <img className='about-description-two__image-girl' src={imageGirl} alt='ленточка' />
                        <img className='about-description-two__image-lenta' src={line} alt='баба' />
                    </div>
                </div>
                <p className='about-description-two__citata'>
                    «СО ВНУТРЕННЕГО И ВНЕШНЕГО ЗДОРОВЬЯ- НАЧИНАЕТСЯ КРАСОТА»
                </p>
            </div>
        </div>
        </div>

    );
}

export default About;