import React from "react";
import headerMainLeftImage from "../assets/headerMainLeftImage.webp";
import headerMainLeftLine from "../assets/headerMainLeftLine.png";
import headerMainRightImage from "../assets/headerMainRightImage.webp";
import headerMainLineImage from "../assets/headerMainRightLine.png";


const HeaderMain = () => {
    return (
        <div className="wrap headerMain">
            <div className="headerMain-container">
                <div className="headerMain-container__left">
                    <div className="headerMain-container__content">
                        <div className="headerMain-container__images">
                            <img className="headerMain-container__image header-container__image--left-girl" src={headerMainLeftImage} alt="фото девушки" />
                            <img className="headerMain-container__image header-container__image--left-line" src={headerMainLeftLine} alt="линии" />
                        </div>
                        <h1 className="headerMain-container__title">
                            Онлайн институт-<br></br>
                            фитнеса Валерии<br></br>
                            Поляковой
                        </h1>
                    </div>
                    <div className="headerMain-container__button">
                        <button className="headerMain__button"><a className="program__a" href="#programs">Все программы</a></button>
                    </div>
                </div>
                <div className="headerMain-container__right">
                    <img className="headerMain-container__image header-container__image--right-line" src={headerMainLineImage} alt="линии" />
                    <img className="headerMain-container__image header-container__image--right-image" src={headerMainRightImage} alt="фото девушки" />
                </div>
            </div>
        </div>
    );
}

export default HeaderMain;